import { type FormikProps, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { type HTMLInputTypeAttribute, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { useStore } from '../_core/appContext';
import { cn } from '../utils/ui';

const loginValidationSchema = yup.object().shape({
   email: yup
      .string()
      .email('Email is not valid!')
      .required('This field is required!'),
   password: yup.string().required('This field is required!'),
});

type LoginFormValues = {
   email: string;
   password: string;
};

const LoginPage = observer(() => {
   const store = useStore();
   const navigate = useNavigate();

   const login = useCallback(
      async (values: LoginFormValues) => {
         await store.user.login(values);
         navigate('/');
      },
      [navigate, store.user],
   );

   const {
      values,
      errors,
      touched,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
   } = useFormik<LoginFormValues>({
      initialValues: {
         email: '',
         password: '',
      },
      validationSchema: loginValidationSchema,
      onSubmit: login,
   });

   return store.user.isLoggedIn ? (
      <Navigate to='/' replace />
   ) : (
      <div className='flex h-screen w-full items-center justify-center'>
         <div className='flex w-full max-w-3xl flex-col items-center justify-center rounded-md bg-boatpark-gray-light py-20'>
            <span className='w-[50%] text-center text-2xl font-medium text-boatpark-black'>
               Boatpark Translation Dashboard Login
            </span>

            <div className='mt-10 flex w-[50%] flex-col gap-y-4'>
               <FormItem
                  name='email'
                  value={values.email}
                  label='Email'
                  placeholder='Enter your email address'
                  error={touched.email && errors.email}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
               />
               <FormItem
                  name='password'
                  value={values.password}
                  label='Password'
                  placeholder='Enter your password'
                  error={touched.password && errors.password}
                  type='password'
                  handleBlur={handleBlur}
                  handleChange={handleChange}
               />
            </div>
            <button
               className={cn(
                  'mt-10 w-[50%] rounded-md bg-boatpark-blue py-2 text-lg font-bold text-white',
                  isSubmitting && 'bg-boatpark-gray',
               )}
               disabled={isSubmitting}
               onClick={() => handleSubmit()}
            >
               Login
            </button>
         </div>
      </div>
   );
});

type FormItemProps = {
   name: string;
   value: string;
   label?: string;
   placeholder?: string;
   type?: HTMLInputTypeAttribute;
   error?: string;
   required?: boolean;
   handleBlur: FormikProps<LoginFormValues>['handleChange'];
   handleChange: FormikProps<LoginFormValues>['handleChange'];
};
const FormItem = ({
   name,
   value,
   label,
   placeholder,
   type = 'text',
   error,
   required,
   handleBlur,
   handleChange,
}: FormItemProps) => {
   return (
      <div className='w-full'>
         <div className='flex flex-col gap-y-2'>
            {label ? (
               <label className='font-medium text-gray-500'>
                  {required ? <span className='text-red-500'>*</span> : null}
                  {`${label}:`}
               </label>
            ) : null}
            <input
               className='rounded border border-black px-5 py-2'
               id={name}
               name={name}
               value={value}
               placeholder={placeholder}
               onBlur={handleBlur}
               onChange={handleChange}
               type={type}
            />
         </div>
         {error ? <span className='text-xs text-red-600'>{error}</span> : null}
      </div>
   );
};

export default LoginPage;
