import { observer } from 'mobx-react-lite';
import React from 'react';
import { Navigate, useOutlet } from 'react-router-dom';

import { useStore } from '../_core/appContext';
import NavBar from '../components/NavBar';

const ProtectedLayout = observer(() => {
   const store = useStore();
   const outlet = useOutlet();

   return !store.user.isLoggedIn ? (
      <Navigate to='/login' replace />
   ) : (
      <div className='flex h-screen w-full flex-col'>
         <NavBar />
         <div className='flex-1 overflow-y-auto'>{outlet}</div>
      </div>
   );
});

export default ProtectedLayout;
