import type { LoginRequestBody, LoginResponse } from '../types';
import request from './_request';

const endpoints = {
   login: '/auth/login',
   logout: '/auth/logout',
};

export default {
   login: (body: LoginRequestBody) => {
      return request.post<LoginResponse>(endpoints.login, body);
   },
   logout: () => {
      return request.get(endpoints.logout);
   },
};
