import { observer } from 'mobx-react-lite';
import React, {
   createContext,
   type ReactNode,
   useContext,
   useMemo,
} from 'react';

import MainStore from '../stores/MainStore';

const storeContext = createContext<MainStore>(null);

export const StoreProvider = observer(
   ({ children }: { children: ReactNode }) => {
      const store = useMemo(() => new MainStore(), []);
      return (
         <storeContext.Provider value={store}>{children}</storeContext.Provider>
      );
   },
);

export const useStore = () => useContext(storeContext);
