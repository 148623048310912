import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import { useStore } from './_core/appContext';
import { router } from './routes';

const MainApp = observer(() => {
   const store = useStore();

   useEffect(() => {
      // do some checks (check logged-in user auth, fetch initial data etc.)
      store.app.setIsLoading(false);
   }, [store.app]);

   return (
      <div>
         {store.app.isLoading ? (
            <b>Loading..</b>
         ) : (
            <RouterProvider router={router} />
         )}
      </div>
   );
});

export default MainApp;
