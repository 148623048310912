import { makeAutoObservable, runInAction } from 'mobx';

import request from '../api/_request';
import AppStore from './AppStore';
import UserStore from './UserStore';

class MainStore {
   app: AppStore;
   user: UserStore;
   constructor() {
      this.app = new AppStore();
      this.user = new UserStore();

      request.setLogoutFunction(() =>
         runInAction(() => {
            this.user.logout();
         }),
      );
      makeAutoObservable(this);
   }
}

export default MainStore;
