import React from 'react';
import { ToastContainer } from 'react-toastify';

import { StoreProvider } from './_core/appContext';
import MainApp from './MainApp';

function App() {
   return (
      <StoreProvider>
         <MainApp />
         <ToastContainer position='top-center' />
      </StoreProvider>
   );
}

export default App;
