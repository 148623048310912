import React, { useCallback, useState } from 'react';

type Props = {
   title?: string;
   message?: string;
   cancelText?: string;
   confirmText?: string;
   onCancelClick: () => void;
   onConfirmClick: () => void | Promise<void>;
};
const ConfirmModal = ({
   title = 'Are you sure?',
   message,
   cancelText = 'Cancel',
   confirmText = 'Yes',
   onCancelClick,
   onConfirmClick,
}: Props) => {
   const [isSubmitting, setIsSubmitting] = useState(false);

   const onConfirm = useCallback(async () => {
      setIsSubmitting(true);
      await onConfirmClick();
      setIsSubmitting(false);
   }, [onConfirmClick]);

   return (
      <div className='fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-25 backdrop-blur-sm'>
         <div className='flex max-w-[600px] flex-col rounded-lg bg-white px-10 py-5'>
            <h1 className='text-lg font-semibold text-boatpark-black'>
               {title}
            </h1>
            {message ? (
               <span className='mt-2 text-boatpark-black'>{message}</span>
            ) : null}
            <div className='mt-4 flex flex-row justify-end'>
               <button
                  className='rounded-md border bg-gray-400 px-4 py-1'
                  onClick={onCancelClick}
               >
                  <span className='text-white'>{cancelText}</span>
               </button>
               <button
                  className='ml-2 rounded-md border bg-red-500 px-4 py-1'
                  disabled={isSubmitting}
                  onClick={onConfirm}
               >
                  <span className='font-medium text-white'>{confirmText}</span>
               </button>
            </div>
         </div>
      </div>
   );
};

export default ConfirmModal;
