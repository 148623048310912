import React from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
   const navigate = useNavigate();

   const handleNavigate = (project: 'app' | 'web') => {
      navigate(`/translations/${project}`);
   };

   return (
      <div className='flex h-full w-full flex-col items-center justify-center px-20 py-10'>
         <div className='flex flex-row gap-x-4'>
            <button
               className='rounded-md bg-boatpark-gray-light px-10 py-5 font-medium text-boatpark-black'
               onClick={() => handleNavigate('web')}
            >
               Web Translations
            </button>
            <button
               className='rounded-md bg-boatpark-gray-light px-10 py-5 font-medium text-boatpark-black'
               onClick={() => handleNavigate('app')}
            >
               App Translations
            </button>
         </div>
      </div>
   );
};

export default HomePage;
