const storage = {
   getString: (key: string): string => {
      return localStorage.getItem(key);
   },
   setString: (key: string, value: string): void => {
      localStorage.setItem(key, value);
   },

   getObject: <T>(key: string): T => {
      const s = localStorage.getItem(key);
      try {
         return JSON.parse(s);
      } catch (err) {
         console.log('Storage JSON.parse error: ', err);
         return null;
      }
   },
   setObject: (key: string, value: object) => {
      localStorage.setItem(key, JSON.stringify(value));
   },
};

export default storage;
