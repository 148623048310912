import { type FormikProps, useFormik } from 'formik';
import React, { useCallback } from 'react';
import * as yup from 'yup';

// TODO: remove duplicate, use common form component for create & update
const addTranslationValidationSchema = yup.object().shape({
   namespace: yup.string().required('This field is required!'),
   name: yup.string().required('This field is required!'),
   en: yup.string().required('This field is required!'),
   de: yup.string().nullable(),
   fr: yup.string().nullable(),
   it: yup.string().nullable(),
   es: yup.string().nullable(),
});

export type TranslationFormValues = {
   namespace: string;
   name: string;
   en: string;
   de: string;
   fr: string;
   it: string;
   es: string;
};

type Props = {
   onClose: () => void;
   onSave: (values: TranslationFormValues) => Promise<void>;
};
const AddTranslationModal = ({ onClose, onSave }: Props) => {
   const handleBgClose = useCallback(
      (e) => {
         if (e.target.id === 'wrapper') {
            onClose();
         }
      },
      [onClose],
   );

   const {
      values,
      errors,
      touched,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
   } = useFormik<TranslationFormValues>({
      initialValues: {
         namespace: '',
         name: '',
         en: '',
         de: '',
         fr: '',
         it: '',
         es: '',
      },
      validationSchema: addTranslationValidationSchema,
      onSubmit: onSave,
   });

   return (
      <div
         id='wrapper'
         className='fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-25 backdrop-blur-sm'
         onClick={handleBgClose}
      >
         {/* Header */}
         <div className='flex flex-col rounded-lg bg-white px-10 py-5'>
            <div className='flex items-center justify-between'>
               <h1 className='text-xl font-medium'>Add Translation</h1>
               <button
                  className='text-boatpark-black underline'
                  onClick={() => {
                     onClose();
                  }}
               >
                  Close
               </button>
            </div>

            {/* Form Items */}
            <div className='mt-8 flex flex-col gap-y-2'>
               <FormItem
                  required
                  name='namespace'
                  value={values.namespace}
                  error={touched.namespace && errors.namespace}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
               />
               <hr />
               <FormItem
                  required
                  name='name'
                  value={values.name}
                  error={touched.name && errors.name}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
               />
               <hr />
               <FormItem
                  required
                  name='en'
                  value={values.en}
                  error={touched.en && errors.en}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
               />
               <hr />
               <FormItem
                  name='de'
                  value={values.de}
                  error={touched.de && errors.de}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
               />
               <hr />
               <FormItem
                  name='fr'
                  value={values.fr}
                  error={touched.fr && errors.fr}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
               />
               <hr />
               <FormItem
                  name='it'
                  value={values.it}
                  error={touched.it && errors.it}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
               />
               <hr />
               <FormItem
                  name='es'
                  value={values.es}
                  error={touched.es && errors.es}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
               />
            </div>

            {/* Action Buttons */}
            <div className='mt-8 flex flex-row justify-end'>
               <button
                  onClick={(e) => {
                     e.preventDefault();
                     onClose();
                  }}
               >
                  <span className='text-red-500 underline'>Cancel</span>
               </button>
               <button
                  type='submit'
                  disabled={isSubmitting}
                  className='ml-4 rounded-md border bg-boatpark-blue px-4 py-1'
                  onClick={() => handleSubmit()}
               >
                  <span className='font-medium text-white'>Save</span>
               </button>
            </div>
         </div>
      </div>
   );
};

type FormItemProps = {
   name: string;
   value: string;
   error?: string;
   required?: boolean;
   handleBlur: FormikProps<TranslationFormValues>['handleChange'];
   handleChange: FormikProps<TranslationFormValues>['handleChange'];
};
const FormItem = ({
   name,
   value,
   error,
   required,
   handleBlur,
   handleChange,
}: FormItemProps) => {
   return (
      <div>
         <div className='flex flex-row gap-x-4'>
            <label className='w-[120px] font-medium text-gray-500'>
               {required ? <span className='text-red-500'>*</span> : null}
               {`${name}:`}
            </label>
            <input
               className='flex-shrink rounded border border-black px-5'
               id={name}
               name={name}
               value={value}
               onBlur={handleBlur}
               onChange={handleChange}
               type='text'
            />
         </div>
         {error ? <span className='text-xs text-red-200'>{error}</span> : null}
      </div>
   );
};

export default AddTranslationModal;
