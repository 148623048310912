import { toast } from 'react-toastify';

import { defaultBaseUrl } from '../_core/config';

type ReqMethod = 'get' | 'post' | 'put' | 'delete';

const headers: {
   Authorization?: string;
} = {};

let baseUrl = `${defaultBaseUrl}`;
let logoutFn = null;

const request = {
   addAuthHeader: (token: string) => {
      headers.Authorization = `Bearer ${token}`;
   },
   removeAuthHeader: () => {
      delete headers.Authorization;
   },
   setBaseUrl: (url: string) => {
      console.log('Setting baseUrl to %s', url);
      baseUrl = `${url}`;
   },
   setLogoutFunction: (fn) => {
      logoutFn = fn;
   },

   send: <TResponse>({
      path,
      method,
      params,
      body,
      isFormData = false,
      extraHeaders = {},
   }: {
      path: string;
      method: ReqMethod;
      params?: Record<string, any>;
      body?: any;
      isFormData?: boolean;
      extraHeaders?: Record<string, string>;
   }): Promise<TResponse> => {
      /*console.log(
      'request info -> path: %s - method: %s - body: %s - params: %s',
      path,
      method,
      body,
      params,
    );*/

      let url;

      if (!isFormData) {
         headers['Content-Type'] = 'application/json';
      }

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
         try {
            const config = {
               method: method,
               body,
               headers: { ...headers, ...extraHeaders },
            };

            if (body) {
               if (!isFormData) {
                  config.body = JSON.stringify(body);
               }
            }

            if (params) {
               for (const param in params) {
                  if (params[param] === undefined || params[param] === null) {
                     delete params[param];
                  }
               }
               const sParams = new URLSearchParams(params);
               url = `${baseUrl}${path}?${sParams}`;
            } else {
               url = `${baseUrl}${path}`;
            }

            // console.log('req url: %s ', url);

            const response = await fetch(url, config);

            if (response.status === 200) {
               try {
                  const json = await response.json();
                  // console.log('Request -> JSON Res: ', json);
                  resolve(json);
               } catch (err) {
                  // console.log('Status: 200 -> Response -> JSON Error: ', err);
                  resolve(null);
               }
            } else if (response.status === 401) {
               console.error(
                  'Status: 401 --> Logging out because of the status 401',
               );
               toast('Unauthenticated! Logging out...', { type: 'error' });
               logoutFn?.();
               reject(response);
            } else if (response.status === 403) {
               console.error('Status: 403 --> Unauthorized.');
               // toast('Unauthorized for this action!', { type: 'error' });
               reject(response);
            } else {
               // console.log('response error: ', response);
               try {
                  const jsonRes = await response.json();
                  console.error(
                     'Status: %s -> Response -> JSON: %s',
                     response.status,
                     jsonRes,
                  );
                  toast(`Unexpected error: ${JSON.stringify(jsonRes)}`, {
                     type: 'error',
                  });
                  reject(jsonRes);
               } catch (err) {
                  console.error(
                     'Status: %s -> Response -> JSON Error: %s',
                     response.status,
                     err,
                  );
                  toast(
                     `JSON parse error - Status ${response.status} - Error: ${err}`,
                     {
                        type: 'error',
                     },
                  );
               }

               reject(response);
            }
         } catch (err) {
            console.error('request error: ', err);
            reject(err);
         }
      });
   },

   get: <TResponse>(path: string, params?) => {
      return request.send<TResponse>({
         path,
         method: 'get',
         params,
      });
   },
   post: <TResponse>(path: string, body?, params?) => {
      return request.send<TResponse>({
         path,
         method: 'post',
         body,
         params,
      });
   },
   put: <TResponse>(path: string, body?, params?) => {
      return request.send<TResponse>({
         path,
         method: 'put',
         body,
         params,
      });
   },
   delete: <TResponse>(path: string, body?, params?) => {
      return request.send<TResponse>({
         path,
         method: 'delete',
         body,
         params,
      });
   },
};
export default request;
