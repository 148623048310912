import { action, makeObservable, observable } from 'mobx';

class AppStore {
   isLoading: boolean = true;

   constructor() {
      makeObservable(this, {
         isLoading: observable,

         setIsLoading: action,
      });
   }

   setIsLoading(val: boolean) {
      this.isLoading = val;
   }
}

export default AppStore;
