import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStore } from '../_core/appContext';

const NavBar = observer(() => {
   const store = useStore();
   const navigate = useNavigate();

   const onLogoutClick = useCallback(() => {
      store.user.logout();
   }, [store.user]);

   return (
      <nav className='flex justify-center bg-boatpark-gray-light py-4'>
         <div className='flex w-full max-w-4xl flex-row items-center justify-between'>
            <button
               className='flex flex-row items-center'
               onClick={() => {
                  navigate('/');
               }}
            >
               <img
                  width={65}
                  height={65}
                  alt='Boatpark'
                  src='https://boatpark.app/wp-content/uploads/2020/04/cropped-logo.png'
               />
               <span className='ml-2.5 text-3xl text-boatpark-black'>
                  Boatpark
               </span>
            </button>
            <div className='flex flex-row items-center'>
               <span className='text-boatpark-black'>{`${store.user.info?.firstname} ${store.user.info?.lastname}`}</span>
               <button
                  className='ml-4 rounded-md border-2 border-boatpark-black px-4 py-2 text-boatpark-black duration-100 hover:bg-boatpark-black hover:text-white'
                  onClick={onLogoutClick}
               >
                  Logout
               </button>
            </div>
         </div>
      </nav>
   );
});

export default NavBar;
