import React from 'react';
import {
   createBrowserRouter,
   createRoutesFromElements,
   Route,
} from 'react-router-dom';

import ProtectedLayout from '../layouts/ProtectedLayout';
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import TranslationPage from '../pages/TranslationPage';

export const router = createBrowserRouter(
   createRoutesFromElements(
      <>
         <Route element={<ProtectedLayout />}>
            <Route path='/' element={<HomePage />} />
            <Route
               path='/translations/web'
               element={<TranslationPage project='boatpark_web' />}
            />
            <Route
               path='/translations/app'
               element={<TranslationPage project='boatpark_app' />}
            />
         </Route>

         <Route path='/login' element={<LoginPage />} />
      </>,
   ),
);
