import { action, makeObservable, observable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import request from '../api/_request';
import authApi from '../api/authApi';
import { UserStorageKey } from '../constants/storageKeys';
import type { LoginRequestBody, User } from '../types';
import storage from '../utils/storage';

type StorageType = {
   isLoggedIn: boolean;
   info: Partial<User>;
   token: string;
};

class UserStore {
   isLoggedIn: boolean = false;
   info: Partial<User> = null;
   token: string = null;

   constructor() {
      const userObj = storage.getObject<StorageType>(UserStorageKey);
      if (userObj) {
         this.isLoggedIn = userObj.isLoggedIn;
         this.info = userObj.info;
         request.addAuthHeader(userObj.token);
      }
      makeObservable(this, {
         isLoggedIn: observable,
         info: observable,

         _reset: action,
      });
   }

   async login({ email, password }: LoginRequestBody) {
      try {
         const res = await authApi.login({ email, password });
         runInAction(() => {
            this.isLoggedIn = true;
            this.info = res.account;
            this.token = res.token;
         });
         request.addAuthHeader(res.token);
         this._persist();
      } catch (err) {
         // console.error('login err: ', err);
         toast('Login failed! Invalid email or password.', { type: 'error' });
         this._reset();
      }
   }
   async logout() {
      try {
         await authApi.logout();
         this._reset();
      } catch (err) {
         console.error('logout err: ', err);
         this._reset();
      }
   }

   _persist() {
      storage.setObject(UserStorageKey, {
         isLoggedIn: this.isLoggedIn,
         info: this.info,
         token: this.token,
      });
   }
   _reset() {
      this.isLoggedIn = false;
      this.info = null;
      request.removeAuthHeader();
      storage.setObject(UserStorageKey, null);
   }
}

export default UserStore;
